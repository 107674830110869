<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-col cols="14" md="14">
      <base-material-card color="#cf9602">
        <template v-slot:heading>
          <div class="text-h3 font-weight-light">Daily Payouts</div>
        </template>
        <v-card-text style="padding: 7px;">
          <v-data-table
            :headers="columns"
            :items="orders"
            item-key="_id"
            hide-default-footer
            disable-pagination
            :loading="loading"
            loading-text="Loading... Please wait"
            :item-class="itemStatusColor"
          >
          </v-data-table>
        </v-card-text>
      </base-material-card>
    </v-col>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  name: "StoreWiseOrders",
  props: [],
  data() {
    return {
      orderStatus: ["Accepted", "Pending", "Pickup"],
      orders: [],
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      loading: true,
      numbers: [],
      storeName: "",
      totalSales: "",
      payoutPercent: "",
      estimatedPayout: 0,
    };
  },
  computed: {
    columns() {
      return [
        {
          text: "Store Name",
          value: "store_name",
          width: "20px",
        },
        {
          text: "Total Sales",
          value: "total_sales",
          width: "20px",
        },
        {
          sortable: false,
          text: "Payout Percent",
          value: "payout_percent",
          width: "20px",
        },
        {
          sortable: false,
          text: "Estimated Payout",
          value: "estimated_payout",
          width: "20px",
        },
      ];
    },
  },
  methods: {
    getOrders() {
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "ordersByStore" + "?page=" + this.page, {
          driverId: null,
          customerId: null,
          orderStatus: this.orderStatus,
        })
        .then((response) => {
          if (response.status == 200) {
            this.orders = response.data.orders;
            console.log("array", this.orders);
            this.loading = false;
            // this.totalPages = response.data.orders.last_page;
            // this.startRecord = response.data.orders.from;
            // this.endRecord = response.data.orders.to;
            // this.totalRecord = response.data.orders.total;
            this.numbers = [];
            for (
              let num = response.data.orders.from;
              num <= response.data.orders.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getOrders();
    },
    itemStatusColor: function (item) {
      if (item.order_status == "Pending") {
        return "pending";
      } else if (item.order_status == "Accepted") {
        return "active";
      } else if (item.order_status == "PickUp") {
        return "pickup";
      } else {
        return;
      }
    },
    format_date(value) {
      if (value) {
        var res = new Date(value * 1000);
        return moment(res).format("DD/MM/YYYY h:m:s");
      }
    },
  },
  mounted() {
    this.getOrders();
  },
};
</script>

<style>
.pending {
  background-color: #ff000021;
}
.active {
  background-color: #fffe0026;
}
.pickup {
  background-color: #fffe0026;
}
.completed {
  background-color: #00800026;
}
</style>